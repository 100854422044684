import React from "react";
import { useMsal } from "@azure/msal-react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
import {ExitIcon} from "./ExitIcon";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance, accounts } = useMsal();
    const [isOpen, setIsOpen] = React.useState(false);
    const ref = React.useRef(null);
    const show = 0;

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    } ,[])

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
        <div className="loginBtnWrapperOffice365" ref={ref}>
            <button  onClick={() => setIsOpen(true)} className="previewBtn previewBtn--white">
                <ExitIcon />
                {accounts && accounts[0] ? accounts[0].name : ''}
            </button>
            {isOpen ? (
                <ul className={"menuItemsWrapperOffice365"}>
                    <li>
                        <button onClick={() => handleLogout("popup")}>Вийти</button>

                    </li>
                </ul>
            ) : null }

        </div>

        // <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Выход">
        //     <Dropdown.Item as="button" onClick={() => handleLogout("popup")}>Выйти из системы</Dropdown.Item>
        //     {show == 1 ? (<Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>Sign out using Redirect</Dropdown.Item>):null}
        // </DropdownButton>
    )
}
