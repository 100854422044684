import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
import {EnterIcon} from "./EnterIcon";
import {ExitIcon} from "./ExitIcon";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();
    const show = 0;

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <div className="loginBtnWrapperOffice365">
            <button  onClick={() => handleLogin("popup")} className="previewBtn previewBtn--white">
                <EnterIcon />
                Вхід та авторизація
            </button>

        </div>

        // <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Войти">
        //     <Dropdown.Item as="button" onClick={() => handleLogin("popup")}>Войти в систему</Dropdown.Item>
        //     {show == 1 ? (<Dropdown.Item as="button" onClick={() => handleLogin("redirect")}>Sign in using Redirect</Dropdown.Item>):null}
        // </DropdownButton>
    )
}
