import React from 'react'

export const ExitIcon = () => {
    return (
        <span className="previewBtn__icon">
            <svg xmlns="http://www.w3.org/2000/svg" className="previewBtnLogo" version="1.1" x="0px" y="0px">
                <g>
                    <polygon
                className="st1" points="19.8,19.7 0,39.3 0,0  " style={{fill: 'rgb(27, 130, 197)'}} /><polygon
                className="st2" points="0.4,44.4 15.6,44.4 44.5,15.4 44.5,0.2  "
                style={{fill: 'rgb(230, 22, 103)'}} />
                    <polygon className="st3" points="44.5,21.2 44.5,44.4 21.6,44.4  " style={{fill: 'rgb(124, 186, 69)'}} /></g></svg>
            {/*<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person"*/}
            {/*     viewBox="0 0 16 16">*/}
            {/*    <path*/}
            {/*        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>*/}
            {/*</svg>*/}
        </span>
    )
}
